@import 'functions';

$font-size-large9xl: getValue(--font-size-large9xl, 62px);
$font-size-large8xl: getValue(--font-size-large8xl, 52px);
$font-size-large7xl: getValue(--font-size-large7xl, 46px);
$font-size-large6xl: getValue(--font-size-large6xl, 40px);
$font-size-large5xl: getValue(--font-size-large5xl, 36px);
$font-size-large4xl: getValue(--font-size-large4xl, 32px);
$font-size-large3xl: getValue(--font-size-large3xl, 28px);
$font-size-large2xl: getValue(--font-size-large2xl, 24px);
$font-size-large1xl: 22px;
$font-size-largexl: getValue(--font-size-largexl, 20px);
$font-size-large: getValue(--font-size-large, 18px);
$font-size-normal3xl: 17px;
$font-size-normal2xl: getValue(--font-size-normal2xl, 16px);
$font-size-normalxl: getValue(--font-size-normalxl, 15px);
$font-size-normal: getValue(--font-size-normal, 14px);
$font-size-small: getValue(--font-size-small, 13px);
$font-size-smallxs: getValue(--font-size-smallxs, 12px);
$font-size-small2xs: getValue(--font-size-small2xs, 11px);
$font-size-smallxxs: getValue(--font-size-smallxxs, 10px);
$font-size-small3xs: getValue(--font-size-small3xs, 9px);

$letter-spacing-xs: getValue(--letter-spacing-xs, -0.01em);

$font-weight-light: getValue(--font-weight-light, 300);
$font-weight-regular: getValue(--font-weight-regular, 400);
$font-weight-extraregular: getValue(--font-weight-extraregular, 500);
$font-weight-semibold: getValue(--font-weight-semibold, 600);
$font-weight-bold: getValue(--font-weight-bold, 700);
$font-weight-extrabold: getValue(--font-weight-extrabold, 900);

$line-height-large: 1.5em;
$line-height-normal: 1.1em;

$spacing-xxxxs: 1px;
$spacing-xxxs: 2px;
$spacing-xxs: 3px;
$spacing-1xs: 4px;
$spacing-xs: 5px;
$spacing-s: 10px;
$spacing-sl: 12px;
$spacing-m: 15px;
$spacing-l: 20px;
$spacing-2l: 25px;
$spacing-xl: 30px;
$spacing-1xl: 40px;
$spacing-2xl: 50px;
$spacing-3xl: 80px;

$borderRadius-none: 0;
$borderRadius-s: 2px;
$borderRadius-m: 3px;
$borderRadius-ml: 4px;
$borderRadius-l: 5px;
$borderRadius-xl: 8px;
$borderRadius-2xl: 10px;
$borderRadius-3xl: 20px;
$borderRadius-button: 22px;

$round-border-radius: 25px;
$round-border-radius-l: 30px;

$badgeCircleDiameter: 24px;

$breakpoint-xxs: 320px;
$breakpoint-xs: 414px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

$breakpoints: (
  'small': $breakpoint-xs,
  'medium': $breakpoint-md,
  'large': $breakpoint-lg,
  'extra-large': $breakpoint-xl,
) !default;

$languageButtonHeight: 100px;
$languageButtonHeight-sm: 150px;
$languageButtonHeight-xs: 100px;
$languageButtonWidth: 231px;

$icon-size-xxs: getValue(--icon-size-xxs, 10px);
$icon-size-s: getValue(--icon-size-s, 30px);
$icon-size-m: getValue(--icon-size-m, 36px);
$icon-size-l: getValue(--icon-size-l, 46px);
$icon-size-xl: getValue(--icon-size-xl, 50px);
