@import 'libs/core/src/assets/styles/base';

$dialog-overlay-color: rgba(34, 33, 33, 0.75);
$dialog-border-radius: 8px;

.p-dialog {
  border-radius: $dialog-border-radius;
  padding: 24px 32px;
  background-color: var(--color-white-background);

  .p-confirm-dialog {
    border: 0;
    font-size: $font-size-normal;
    @include min-sm {
      font-size: $font-size-normal2xl;
    }
  }

  .p-dialog-header {
    border: none;
    padding: 0 0 24px 0;

    h6 {
      font-size: $font-size-large;
      font-weight: $font-weight-semibold;
      line-height: 26px;
      margin: 0;
      padding: 0;

      @include min-sm {
        font-size: $font-size-large2xl;
        line-height: 32px;
      }
    }
  }

  .p-dialog-content {
    padding: 1px;

    &,
    .p-confirm-dialog-message {
      margin: 0;

      > *:first-of-type {
        margin-top: 0;
      }

      > *:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .p-dialog-footer {
    border: none;
    padding: 24px 0 0 0;
  }

  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    background-color: transparent;
  }
}
