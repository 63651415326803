:root {
  /* colors */
  --color-grey-purewhite: #ffffff;
  --color-grey-1: #f8f8f8;
  --color-grey-1-darker: #f4f4f4;
  --color-grey-2: #eeeeee;
  --color-grey-3: #dddddd;
  --color-grey-3-darker: #d3d3d3;
  --color-grey-4: #cccccc;
  --color-grey-5: #aaaaaa;
  --color-grey-6: #808080;
  --color-grey-7: #606060;
  --color-grey-7-darker: #4444444;
  --color-grey-8: #404040;
  --color-grey-8-darker: #303030;
  --color-grey-9: #202020;
  --color-grey-pureblack: #000000;

  /* typography */

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-extraregular: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  --line-height-large: 1.5em;
  --line-height-normal: 1.1em;

  --font-size-large9xl: 62px;
  --font-size-large8xl: 52px;
  --font-size-large7xl: 46px;
  --font-size-large6xl: 40px;
  --font-size-large5xl: 36px;
  --font-size-large4xl: 32px;
  --font-size-large3xl: 28px;
  --font-size-large2xl: 24px;
  --font-size-largexl: 20px;
  --font-size-large: 18px;
  --font-size-normal2xl: 16px;
  --font-size-normalxl: 15px;
  --font-size-normal: 14px;
  --font-size-small: 13px;
  --font-size-smallxs: 12px;
  --font-size-small2xs: 11px;
  --font-size-smallxxs: 10px;
  --font-size-small3xs: 9px;

  --letter-spacing-xs: -0.01em;

  --icon-size-xxs: 10px;
  --icon-size-s: 30px;
  --icon-size-m: 36px;
  --icon-size-l: 46px;
  --icon-size-xl: 50px;
}
