@import 'sizes';

@mixin when-device-is($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}";
  }
}

@mixin min-xs {
  @media (min-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin min-xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin min-custom($customBreakpoint) {
  @media (min-width: #{$customBreakpoint}) {
    @content;
  }
}
