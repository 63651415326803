@import './variables';

@mixin pathways-form-field {
  display: flex;
  color: $form-text-color;
  margin: 0 0 24px 0;
  padding: 0;
  flex-direction: column;

  &.pw-form-field--no-margin {
    margin: 0;
  }
}

@mixin pathways-form-field--inline {
  display: flex;
  color: $form-text-color;
  margin: 0 0 24px 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
}
