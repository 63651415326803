@import '../variables';
@import './variables';

@mixin pathways-input-border {
  border: 1px solid var(--color-kp-neutral-2);
  box-sizing: border-box;
  border-radius: $borderRadius-ml;

  &:focus,
  &:focus-within,
  &:not(.p-disabled).p-focus {
    border: 1px solid var(--color-kp-blue-3);
    box-shadow: 0 0 0 2px var(--color-blue-light);
  }

  &:disabled:-webkit-autofill,
  &:disabled:-webkit-autofill:focus {
    background: $input-disabled-background-color !important;
    box-shadow: 0 0 0 50px $input-disabled-background-color inset;
    -webkit-text-fill-color: $input-disabled-text-color;
    -webkit-opacity: 1;
  }

  &:disabled {
    border: 1px solid $input-disabled-border-color;
  }

  &:invalid,
  &[aria-invalid='true'],
  &:has(input:invalid),
  &:has(input[aria-invalid='true']) {
    @include pathways-input--invalid;
  }
}

@mixin pathways-input-padding {
  padding: 11px 17px;
}

@mixin pathways-input-without-border {
  & {
    background: #ffffff;
    width: 100%;
    font-size: var(--font-size-normal2xl);
    line-height: 24px;
    vertical-align: middle;
    @include pathways-input-padding;
  }

  &::placeholder {
    color: $input-disabled-placeholder-color;
  }

  &:read-only:not(ul),
  &:disabled {
    @include pathways-input--disabled;
  }

  &.pw-input--with-right-icon {
    padding-right: 48px;
  }

  &.pw-input--with-left-icon {
    padding-left: 48px;
  }

  &:focus:enabled {
    outline: 0 none;
    box-sizing: border-box;
  }

  &::-ms-reveal {
    display: none;
  }

  &[aria-invalid='true'] {
    @include pathways-input--invalid;
  }
}

@mixin pathways-input-radio {
  width: auto;
}

@mixin pathways-input {
  @include pathways-input-border;
  @include pathways-input-without-border;

  &[aria-invalid='true'] {
    @include pathways-input--invalid;
  }

  &[type='radio'] {
    @include pathways-input-radio;
  }
}

@mixin pathways-input--disabled {
  background: $input-disabled-background-color;
  border: 1px solid $input-disabled-border-color;
  color: $input-disabled-text-color;
  opacity: 1;

  &:disabled:-webkit-autofill,
  &:disabled:-webkit-autofill:focus {
    -webkit-text-fill-color: $input-disabled-text-color;
    -webkit-opacity: 1;
  }
}

@mixin pathways-input--invalid {
  border-color: var(--color-error);

  &:focus,
  &:focus-within,
  &:not(.p-disabled).p-focus {
    border: 1px solid var(--color-error);
    box-shadow: 0 0 0 2px var(--color-red-light);
  }
}

@mixin pathways-input--password {
  @include pathways-input;
  padding-right: 42px;
}

@mixin pathways-input--password__icon {
  right: 16px;
  height: 21px;
  width: 21px;
  font-size: 21px;
  margin-top: -10.5px;
  color: var(--color-grey-80);
  cursor: pointer;
}

@mixin pathways-input--checkbox {
  height: $checkbox-size;
  width: $checkbox-size;
  background: #ffffff;
  border: 1px solid var(--color-grey-80);
  box-sizing: border-box;
  border-radius: 3px;
}

.pw-input-wrapper {
  position: relative;

  .pw-icon {
    cursor: pointer;
  }
}
