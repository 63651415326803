.pw-tooltip {
  &.p-tooltip.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: #ffffff;
      left: 25%;
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

  .p-tooltip-text {
    color: var(--color-grey);
    background: #ffffff;
    margin: -1px 0 0 0;
    border: 1px solid var(--color-kp-neutral-6);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 12px 18px;
    font-size: 14px;
    line-height: 22px;
  }
}
