$icon-default-size: 24px;
$icon-padding: 12px;

.pw-icon {
  width: $icon-default-size;
  height: $icon-default-size;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &-eye {
    @extend .pw-icon;
    background-image: url('/assets/icons/eye.svg');
  }

  &-eye-slash {
    @extend .pw-icon;
    background-image: url('/assets/icons/eye-slash.svg');
  }

  &-circle-off {
    @extend .pw-icon;
    background-image: url('/assets/icons/circle-off.svg');
  }

  &-circle-checked {
    @extend .pw-icon;
    background-image: url('/assets/icons/circle-checked.svg');
  }

  &-warn,
  &-warning-filled {
    background-image: url('/assets/icons/warning-filled.svg');
  }

  &-success {
    background-image: url('/assets/icons/checkmark-filled.svg');
  }

  &-error {
    background-image: url('/assets/icons/close-filled.svg');
  }

  &-arrow-left {
    background-image: url('/assets/icons/arrow-left.svg');
  }

  &-refresh {
    background-image: url('/assets/icons/refresh.svg');
  }

  &-close {
    background-image: url('/assets/icons/close.svg');
  }

  &-edit {
    background-image: url('/assets/icons/edit.svg');
  }

  &-bell {
    background-image: url('/assets/icons/bell.svg');
  }

  &--right,
  &--left {
    position: absolute;
    top: 50%;
    margin-top: -($icon-default-size/2);
  }

  &--right {
    right: $icon-padding;
  }

  &--left {
    left: $icon-padding;
  }
}
