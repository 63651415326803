:root {
  /* colors */
  --color-error: var(--color-kp-red-3);
  --color-red-light: #eecdd7;
  --color-red-light-40: #f8ebef;
  --color-green: #2b8623;
  --color-green-regular: var(--color-green);
  --color-green-deep: #2b6917;
  --color-green-light-40: #e9f3e9;
  --color-accent-yellow: var(--color-kp-yellow-3);
  --color-yellow-deep: #c18a24;
  --color-yellow-light: #fbe9a7;
  --color-yellow-light-40: #fef6dc;
  --color-blue-light: #abd4ff;
  --color-blue-light-30: #e5f2ff;
  --color-grey: #222121;
  --color-grey-40: #d9d9d8;
  --color-grey-80: #4a494e;
  --color-white-background: #fbfbfb;

  /* new colour palette */
  /* neutral */
  --color-kp-neutral-1: #000000;
  --color-kp-neutral-2: #212121;
  --color-kp-neutral-3: #4a4a4a;
  --color-kp-neutral-4: #a0a0a0;
  --color-kp-neutral-5: #d9d9d9;
  --color-kp-neutral-6: #efefef;
  --color-kp-neutral-7: #ffffff;
  /* purple */
  --color-kp-purple-1: #0d0a3f;
  --color-kp-purple-2: #240f6e;
  --color-kp-purple-3: #503f8b;
  --color-kp-purple-4: #7c6ca7;
  --color-kp-purple-5: #a79fc5;
  --color-kp-purple-6: #e2e3fa;
  /* blue */
  --color-kp-blue-1: #08176e;
  --color-kp-blue-2: #082ecf;
  --color-kp-blue-3: #005de8;
  --color-kp-blue-4: #c8e1fc;
  --color-kp-blue-5: #e8f4ff;
  /* green */
  --color-kp-green-1: #002d12;
  --color-kp-green-2: #00491e;
  --color-kp-green-3: #287d21;
  --color-kp-green-4: #c8e1c8;
  --color-kp-green-5: #eaf4ea;
  /* lime */
  --color-kp-lime-1: #5c6807;
  --color-kp-lime-2: #8fad15;
  --color-kp-lime-3: #c0df16;
  --color-kp-lime-4: #d9ea9a;
  --color-kp-lime-5: #f4f9e1;
  /* yellow */
  --color-kp-yellow-0: #724100;
  --color-kp-yellow-1: #995700;
  --color-kp-yellow-2: #cf7f00;
  --color-kp-yellow-3: #ffc82e;
  --color-kp-yellow-4: #f8e59a;
  --color-kp-yellow-5: #fff4d2;
  /* orange */
  --color-kp-orange-1: #87200b;
  --color-kp-orange-2: #b52606;
  --color-kp-orange-3: #f45100;
  --color-kp-orange-4: #f9c1a5;
  --color-kp-orange-5: #ffefe7;
  /* red */
  --color-kp-red-1: #720c2b;
  --color-kp-red-2: #9e1539;
  --color-kp-red-3: #d6083b;
  --color-kp-red-4: #f5cabf;
  --color-kp-red-5: #fce9e6;
  /* pink */
  --color-kp-pink-1: #6d0053;
  --color-kp-pink-2: #ab0c78;
  --color-kp-pink-3: #de1b90;
  --color-kp-pink-4: #f8bed6;
  --color-kp-pink-5: #ffe1ef;
}
