body {
  margin: 0;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

a {
  text-decoration-line: underline;
  color: var(--color-kp-blue-3);
  cursor: pointer;
}
