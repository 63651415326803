@import 'sizes';
@import 'functions';
/* Google Material Design Box Shadow: https://gist.github.com/paulmelnikow/7fcfbe4f68e5df7fe326 */
@mixin box_shadow($level) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin show-item-list-selected-icon($top: $spacing-s, $right: $spacing-s) {
  &:after {
    position: absolute;
    top: $top;
    right: $right;
    mask: url('#{$assetIconPath}/solid-tick-circle.svg');
    mask-size: $font-size-large;
    background-color: $white;
    width: $font-size-large;
    height: $font-size-large;
    content: '';
  }
}

@mixin logo-image {
  background-image: getValue(--logo-image-url);
}

@mixin help-image {
  background-image: getValue(--help-image-url);
}
