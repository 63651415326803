.p-breadcrumb {
  background: unset !important;
  border: 0;
  padding: 6px 16px 16px 0;
  background: var(--color-white-background);

  ul {
    li {
      .pi-arrow-left:before {
        padding: 3px 2px;
        font-size: 14px;
      }

      a {
        &.p-menuitem-link {
          span {
            &.p-menuitem-text {
              color: var(--color-kp-blue-3);
              text-decoration: underline;
            }
            &.p-menuitem-icon {
              color: var(--color-grey);
            }
          }
        }
      }
    }
  }

  li {
    &.pi-chevron-right,
    &.p-breadcrumb-separator {
      display: none;
    }
  }

  span {
    &.pi-arrow-left {
      margin-right: 5px;
    }
  }
}
