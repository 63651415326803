:focus-visible,
:focus-within {
  outline: none !important;
}

#ccc-notify-reject,
#ccc-reject-settings {
  border-color: var(--color-kp-blue-3) !important;
}

#ccc-notify .ccc-notify-buttons {
  display: flex;
  flex-direction: row-reverse;

  .ccc-notify-link span {
    color: var(--color-kp-blue-3) !important;
  }
}

#ccc-statement p a .ccc-statement-name {
  color: var(--color-kp-blue-3) !important;
  text-decoration: underline;
  text-decoration-color: var(--color-kp-blue-3) !important;
}

#ccc-optional-categories {
  .checkbox-toggle {
    &.checkbox-toggle--slider {
      box-shadow: 0 0 0 1px var(--color-kp-neutral-3);
    }

    &.checkbox-toggle--slider-on {
      box-shadow: 0 0 0 1px transparent;
      background-color: var(--color-kp-blue-3) !important;
      border-color: var(--color-kp-blue-3) !important;

      .checkbox-toggle-on {
        color: #ffffff !important;
      }

      .checkbox-toggle-toggle {
        background-color: #ffffff !important;
      }
    }
  }
}
