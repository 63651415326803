@import './variables';

@mixin pathways-label {
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-normal2xl);
  line-height: 1.5em;
  margin: 2px 0 4px 0;
}

@mixin pathways-label--checkbox {
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 16px;
  line-height: 24px;
  color: $form-text-color;
  margin-left: 14px;
}
