@import './styles/primeng-override/';
@import 'libs/pathways/shared/styles/form';
@import 'libs/pathways/shared/styles/icons';
@import 'libs/pathways/shared/styles/cookies';
@import 'libs/pathways/shared/styles/tooltip';
@import 'libs/pathways/shared/styles/breadcrumb';

.pw-form-field {
  @include pathways-form-field;

  label {
    @include pathways-label;
  }

  input {
    @include pathways-input;
  }

  .p-password {
    width: 100%;

    input {
      @include pathways-input--password;
    }

    &.p-input-icon-right {
      > i:last-of-type {
        @include pathways-input--password__icon;
      }
    }
  }
}

.pw-form-field-checkbox {
  @include pathways-form-field--inline;

  label {
    @include pathways-label--checkbox;
  }

  .p-checkbox {
    height: $checkbox-size;
    width: $checkbox-size;

    .p-checkbox-box {
      @include pathways-input--checkbox;
    }
  }
}

.pw-form-field input.pw-form-input--invalid,
.pw-form-input--invalid {
  @include pathways-input--invalid;
}
