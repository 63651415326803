:root {
  /* Primary Colours */
  --button-primary-background: var(--color-kp-blue-3);
  --button-primary-text-color: white;
  --button-primary-border-color: var(--color-kp-blue-3);

  --button-primary-background-disabled: var(--color-kp-neutral-6);
  --button-primary-text-color-disabled: var(--color-kp-neutral-4);
  --button-primary-border-disabled: var(--color-grey-40);
  --button-primary-opacity-disabled: 1;

  --button-primary-background-hover: #082ecf;
  --button-primary-text-color-hover: white;
  --button-primary-border-color-hover: #082ecf;

  --button-primary-background-focus: #082ecf;
  --button-primary-text-color-focus: white;
  --button-primary-border-color-focus: #082ecf;

  --button-primary-background-active: var(--color-kp-blue-3);
  --button-primary-text-color-active: white;
  --button-primary-border-color-active: var(--color-kp-blue-3);

  --button-primary-box-shadow: 0px 2px 4px rgba(8, 46, 207, 0.2);
  --button-primary-box-shadow-focus: 0px 2px 4px rgba(8, 46, 207, 0.25);
  --button-primary-box-shadow-active: inset 0px 5px 2px rgba(8, 46, 207, 0.3);

  /* Secondary Colours */
  --button-secondary-background: white;
  --button-secondary-text-color: var(--color-kp-blue-3);
  --button-secondary-border-color: var(--color-kp-blue-3);

  --button-secondary-background-disabled: var(--button-primary-background-disabled);
  --button-secondary-text-color-disabled: var(--button-primary-text-color-disabled);
  --button-secondary-border-disabled: var(--button-primary-border-disabled);

  --button-secondary-background-hover: #f2f4fc;
  --button-secondary-text-color-hover: var(--button-secondary-text-color);
  --button-secondary-border-color-hover: #f2f4fc;

  --button-secondary-background-focus: #f2f4fc;
  --button-secondary-text-color-focus: var(--button-secondary-text-color);
  --button-secondary-border-color-focus: #f2f4fc;

  --button-secondary-background-active: #f2f4fc;
  --button-secondary-text-color-active: var(--button-secondary-text-color);
  --button-secondary-border-color-active: #f2f4fc;

  --button-secondary-box-shadow: var(--button-primary-box-shadow-focus);
  --button-secondary-box-shadow-active: inset 0px 3px 3px rgba(8, 46, 207, 0.2);

  /* Link Button Colours */
  --button-link-text-color-disabled: var(--color-grey-40);

  /* Font Sizes */
  --button-font-size-xs: var(--button-font-size-s);
  --button-font-size-s: var(--font-size-normal);
  --button-font-size-m: var(--font-size-normal2xl);
  --button-font-size-l: var(--button-font-size-m);
  --button-font-size-xl: var(--button-font-size-m);

  /* Line Heights */
  --button-line-height-xs: var(--button-line-height-s);
  --button-line-height-s: 19px;
  --button-line-height-m: 22px;
  --button-line-height-l: var(--button-line-height-m);
  --button-line-height-xl: var(--button-line-height-m);

  /* Font Weights */
  --button-font-weight: var(--font-weight-semibold);

  /* Border Radius */
  --button-border-width: 1px;
  --button-border-radius: 4px;
  --button-border-radius-xs: var(--button-border-radius);
  --button-border-radius-s: var(--button-border-radius);
  --button-border-radius-m: var(--button-border-radius);
  --button-border-radius-l: var(--button-border-radius);
  --button-border-radius-xl: var(--button-border-radius);

  /* Padding */
  --button-padding-xs: var(--button-padding-s);
  --button-padding-s: 7px 16px;
  --button-padding-m: 12px 16px;
  --button-padding-l: var(--button-padding-m);
  --button-padding-xl: var(--button-padding-m);

  /* Border Widths */
  --button-border-width-xs: var(--button-border-width-s);
  --button-border-width-s: 1px;
  --button-border-width-m: 1px;
  --button-border-width-l: var(--button-border-width-m);
  --button-border-width-xl: var(--button-border-width-m);
}
